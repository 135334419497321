import React from "react";
import { GallerySchema, PictureDisplayStyle } from "../../interface";

export const config: GallerySchema = {
  id: 'folkways_2',
  title: '拜神',
  intro: '年終拜神，原是北方先民的一種風俗，最早在先秦時。每年年底莊稼收割後，答謝蒼天的恩賜的一種儀式。潮陽的祖先是晉朝後，為逃避北方戰亂，從中原逐步移民過來的，由於地處偏僻，少受到外來文化的沖擊。因此，很多習俗也就世代相傳下來，拜神就成為潮汕一年中每個家庭最為隆重、最為重要不可缺少的一件大事。',
  description: <div>
    年終拜神，原是北方先民的一種風俗，最早在先秦時。每年年底莊稼收割後，答謝蒼天的恩賜的一種儀式。潮陽的祖先是晉朝後，為逃避北方戰亂，從中原逐步移民過來的，由於地處偏僻，少受到外來文化的沖擊。因此，很多習俗也就世代相傳下來，拜神就成為潮汕一年中每個家庭最為隆重、最為重要不可缺少的一件大事。
  </div>,
  ratio: [2, 1],
  tags: ['iPhone', 'VSCO', '潮汕'],
  pages: [
    {
      picture: ['http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/ACFF7C48-49D7-4544-89F6-F76A58A7CAC3.JPG-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/D6E43D97-E724-438B-ABF4-91E7B1981136.JPG-smallstyle',
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/CA7A7210-232D-41AD-96B7-B72D233089F2.JPG-smallstyle',
      ],
      display: [
        PictureDisplayStyle.FULL,
        PictureDisplayStyle.FULL,
      ],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/C0404ED8-717A-469E-B5EE-2E762EFB56E4.JPG-smallstyle',
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/DC61F56F-DBF0-4F04-9D17-79980AC98477.JPG-smallstyle',
      ],
      display: [
        PictureDisplayStyle.FULL,
        PictureDisplayStyle.FULL,
      ],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/D18AC061-3268-4864-86C6-DEB5B6305015.JPG-smallstyle',
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/E1B7DE0B-A696-4CD1-A2AB-9A60996C388C.JPG-smallstyle',
      ],
      display: [
        PictureDisplayStyle.FULL,
        PictureDisplayStyle.FULL,
      ],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/68FFF18F-AA2E-4DE1-8519-6B13195C983C.JPG-smallstyle',
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/2B2257CB-94BE-44DF-A919-4C8F1B36BDE3.JPG-smallstyle',
      ],
      display: [
        PictureDisplayStyle.FULL,
        PictureDisplayStyle.FULL,
      ],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/C2CD555B-17C4-44E9-8E96-312E9AE535E6.JPG-smallstyle',
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/64A44364-AE2C-4EE1-A90C-8ECCE8DD27EB.JPG-smallstyle',
      ],
      display: [
        PictureDisplayStyle.FULL,
        PictureDisplayStyle.FULL,
      ],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/1A9E973F-A212-45ED-81AC-A89B0EC52499.JPG-smallstyle',
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/5089EB58-D481-41FA-87D4-EC287A2126C0.JPG-smallstyle',
      ],
      display: [
        PictureDisplayStyle.FULL,
        PictureDisplayStyle.FULL,
      ],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/88378A82-0410-4726-837C-A537A7A61C4C.JPG-smallstyle',
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/BE01B070-D273-4346-BAFD-411D714A71CC.JPG-smallstyle',
      ],
      display: [
        PictureDisplayStyle.FULL,
        PictureDisplayStyle.FULL,
      ],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/62676090-9F14-4BB6-9DEB-BA903E0DB190.JPG-smallstyle',
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/D9A6122B-D931-472B-AA2F-433B7D926697.JPG-smallstyle',
      ],
      display: [
        PictureDisplayStyle.FULL,
        PictureDisplayStyle.FULL,
      ],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/EB62F008-CDED-4617-905F-D693A515038A.JPG-smallstyle',
        'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/17755EA0-ED45-4BA8-9C28-DDBE64246D02.JPG-smallstyle',
      ],
      display: [
        PictureDisplayStyle.FULL,
        PictureDisplayStyle.FULL,
      ],
    },
    // {
    //   picture: [
    //     'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/41BD0148-22AD-4CC7-A402-D2A70943A4E0.JPG-smallstyle',
    //     'http://cdn.harrybetter.top/%E6%BD%AE%E6%B1%95/%E6%8B%9C%E7%A5%9E/7D21AB10-C832-4862-8A22-5A1EA155EE44.JPG-smallstyle',
    //   ],
    //   display: [
    //     PictureDisplayStyle.FULL,
    //     PictureDisplayStyle.FULL,
    //   ],
    // },
  ],
};
