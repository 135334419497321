import React from "react";
import { GallerySchema, PictureDisplayStyle } from "../../interface";

export const config: GallerySchema = {
  id: 'humanities_1',
  title: '彩虹邨',
  intro: '彩虹邨見證了香港的發展歷程，是香港公共房屋的一個重要里程碑。彩虹邨不僅提供了經濟實惠的住房，也成為了一個充滿活力和生命力的社區，展現了香港多元文化的魅力。',
  description: <div>
    <p>彩虹邨是香港的一個公共房屋屋邨，位於九龍觀塘區，建於1975年，是香港最早的公共房屋之一。彩虹邨共有十二座大廈，樓高三十至四十層不等，提供約6,000個單位，是香港最大的公共房屋之一。</p>
    <p>彩虹邨的設計獨特，以彩虹為主題，每座大廈的外牆都是不同的顏色，從遠處看去，彩虹邨就像一條繽紛的彩虹橋。彩虹邨的公共空間設施齊全，包括學校、商場、運動場、圖書館等，為居民提供了便利的生活環境。</p>
    <p>彩虹邨的居民以低收入家庭為主，他們來自不同的族裔和文化背景，形成了一個多元化的社區。彩虹邨的社區活動豐富多彩，有各種文化、體育、藝術等活動，居民之間互相幫助，形成了一個和諧、友愛的社區。</p>
    <p> 彩虹邨見證了香港的發展歷程，是香港公共房屋的一個重要里程碑。彩虹邨不僅提供了經濟實惠的住房，也成為了一個充滿活力和生命力的社區，展現了香港多元文化的魅力。</p>
  </div>,
  // 页面比例
  ratio: [2, 1],
  tags: ['iPhone', 'VSCO', '香港'],
  // 每一页配置
  pages: [
    {
      picture: ['http://cdn.harrybetter.top/2019-05-01-hk-238D72B5-6E2E-4D74-9EDB-FD991C0ADEBC.JPG'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/2019-05-01-hk-1ADA6A61-187B-42FE-A4E3-190B78E25B8D.JPG', 'http://cdn.harrybetter.top/2019-05-01-hk-2497B4BE-98A8-4E56-8C91-1961A5B94611.JPG'],
      display: [PictureDisplayStyle.FULL, PictureDisplayStyle.CENTER],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/2019-05-01-hk-E939442D-DAE9-494A-9EC3-13C27CA76C1C.JPG',
        'http://cdn.harrybetter.top/2019-05-01-hk-BD955151-D297-46B6-BC17-8EC0CF83092F.JPG',
      ],
      display: [PictureDisplayStyle.CENTER, PictureDisplayStyle.FULL],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/2019-05-01-hk-51D150BA-382A-4A9B-9C2C-B5F1A53190A7.JPG',
      ],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/2019-05-01-hk-F7154D67-D1B5-4EA1-BD17-6D911C55708B.JPG',
        'http://cdn.harrybetter.top/2019-05-01-hk-4226B921-5F0C-4395-AE32-F870C646FDA5.JPG',
      ],
      display: [PictureDisplayStyle.FULL, PictureDisplayStyle.CENTER],
    },
  ],
}
