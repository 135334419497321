import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Image } from 'antd';
import AspectRatio from '@mui/joy/AspectRatio';
import Avatar from '@mui/joy/Avatar';
import Chip from '@mui/joy/Chip';
import { HOME_GALLERY_ID, categorySchema } from '../../gallery-schema';
import { GalleryPage, GallerySchema, PictureDisplayStyle } from '../../interface';

import './index.css';

interface GalleryProps {
  category?: string;
}

export default function Gallery(props: GalleryProps) {
  const { id = HOME_GALLERY_ID } = useParams();
  const { category = Object.keys(categorySchema)[0] } = props;
  // @ts-ignore
  const gallerySchema = categorySchema[category].pages[id] as GallerySchema;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = gallerySchema.title;
    document.querySelector('meta[name="title"]')!.setAttribute('content', gallerySchema.title ?? '');
    document.querySelector('meta[name="description"]')!.setAttribute('content', gallerySchema.intro ?? '');
    document.querySelector('meta[name="keywords"]')!.setAttribute('content', gallerySchema.intro ?? '');
    document.querySelector('meta[property="og:image"]')!.setAttribute('content', gallerySchema.pages[0].picture[0].replace('-smallstyle', ''));
  }, [gallerySchema.intro, gallerySchema.pages, gallerySchema.title, id]);

  const renderPage = (page: GalleryPage) => {
    return page.display.length > 1 ? (
      page.display.map((display, index) => (
        <div className="gallery-half-page" key={index}>
          <Image src={page.picture[index]} width={display === PictureDisplayStyle.FULL ? '100%' : '60%'} />
        </div>
      ))
    ) : (
      <Image style={page.style} src={page.picture[0]} />
    );
  };

  return (
    <div>
      <div className="gallery-info">
        <h1>{gallerySchema.title}</h1>
        <div>{gallerySchema.description}</div>
        <div className='gallery-tags'>
          {
            gallerySchema.tags.map(tag => (
              <Chip key={tag} size="lg" variant="outlined">{tag}</Chip>
            ))
          }
        </div>
      </div>
      <div className="gallery-pages">
        {
          gallerySchema.pages.map((schema, index) => (
            <div className="gallery-page" key={index}>
              <div className='gallery-page-num'>
                <Avatar size="sm" variant="outlined">{index + 1}</Avatar>
              </div>
              <AspectRatio ratio={`${gallerySchema.ratio[0]}/${gallerySchema.ratio[1]}`}>
                <div className="gallery-container">
                  {renderPage(schema)}
                </div>
              </AspectRatio>
            </div>
          ))
        }
      </div>
    </div>
  );
}