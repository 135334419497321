import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Menu } from 'antd';
import type { MenuProps } from 'antd';

import './index.css';
import { categorySchema } from '../../gallery-schema';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = Object.values(categorySchema).map((category) => {
  return getItem(
    <div className="nav-item">
      {category.name}
    </div>,
    category.id,
    null,
    // @ts-ignore
    Object.values(category.pages).map(page => getItem(
      <Link to={`/${category.id}/${page.id}`}>
        {page.title}
      </Link>,
      page.id,
    )),
  );
});

export default function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const hideNav = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };
  const isMobile = width <= 600;
  const [openKeys, setOpenKeys] = useState(['']);
  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && Object.keys(categorySchema).indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const extraMenuProps = isMobile ? {
    openKeys,
    onOpenChange,
  } : {};

  return (
    <nav
      id="theme-nav"
      className={isOpen ? 'open' : ''}
    >
      <div className="inner">
        <div className="title">HARRY</div>
        <div className="nav-arrow"></div>
        <div className="nav-items">
          <span className="nav-item nav-item-home" onClick={toggleNav}></span>
          <Menu
            key={isMobile ? 'mobile-menu' : 'pc-menu'}
            mode={isMobile ? 'inline' : 'horizontal'}
            items={items}
            onClick={hideNav}
            {...extraMenuProps}
          />
        </div>
      </div>
    </nav>
  );
}