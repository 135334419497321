import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FloatButton } from 'antd';
import Nav from './components/Nav';
import Gallery from './components/Gallery';
import useTheme from './hooks/switch-theme';
import { categorySchema } from './gallery-schema';

import MoonIcon from './assets/icon/moon';

import './css/var.css'
import './css/main.css';
import './css/overwrite.css';

const routes: any[] = [{
  path: "/",
  component: <Gallery />
}];
Object.values(categorySchema).forEach(category => {
  routes.push({
    path: `/${category.id}`,
    routes: [
      {
        path: "/:id",
        component: <Gallery category={category.id} />
      },
    ]
  });
});

const routeArr: any[] = [];
const routeWithSubRoutes = (routes: any, parentPath: string = '') => (
  routes.forEach((route: any, index: number) => {
    if (route.routes) return routeWithSubRoutes(route.routes, parentPath + route.path);
    routeArr.push(
      <Route
        key={parentPath + route.path + index}
        path={parentPath + route.path}
        element={route.component}
      />
    );
  })
);
routeWithSubRoutes(routes);

function App() {
  const [theme, toggleTheme] = useTheme();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <BrowserRouter basename="/">
      <div className={`root ${width > 600 ? 'root-pc' : 'root-mobile'}`}>
        <Nav />
        <Routes>
          {routeArr}
        </Routes>
        <FloatButton
          type={theme === 'light' ? 'default' : 'primary'}
          // @ts-ignore
          onClick={toggleTheme}
          icon={<MoonIcon />}
        />
        <div className="copyright" style={{ textAlign: 'center', marginBottom: '80px' }}>
          Copyright © Harry 2024
          <br />
          <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">粤ICP备18145967号</a>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
