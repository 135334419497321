import React from "react";
import { GallerySchema, PictureDisplayStyle } from "../../interface";

export const config: GallerySchema = {
  id: 'city_hk_dt',
  title: '马赛克港铁',
  intro: '港鐵的馬賽克瓷磚採用了大量的鮮艷顏色，包括紅色、黃色、藍色、綠色、紫色等，這些顏色組合在一起，創造出了一個充滿活力和生機的視覺效果。',
  description: <div>
    <p>港鐵的馬賽克風格是其標誌性的設計之一，它採用了五彩繽紛的馬賽克瓷磚，創造出了一個充滿活力和活潑氣息的視覺效果。這種風格的馬賽克瓷磚通常被用於月台和地鐵隧道的裝飾，為乘客提供了一個獨特而愉悅的旅行體驗。</p>
    <p>港鐵的馬賽克瓷磚採用了大量的鮮艷顏色，包括紅色、黃色、藍色、綠色、紫色等，這些顏色組合在一起，創造出了一個充滿活力和生機的視覺效果。馬賽克瓷磚的設計通常是由小塊瓷磚組成的圖案，這些圖案可以是幾何形狀、花卉圖案、人物形象等，每個站點的馬賽克瓷磚的設計都是獨一無二的，反映了香港不同地區的文化和特色。</p>
    <p>總之，港鐵的馬賽克風格是一個充滿活力和活潑氣息的設計，它採用了五彩繽紛的馬賽克瓷磚，為乘客提供了一個獨特而愉悅的旅行體驗。</p>
  </div>,
  // 页面比例
  ratio: [1, 1],
  tags: ['iPhone', 'VSCO', '香港'],
  // 每一页配置
  pages: [
    {
      picture: ['http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%9C%B0%E9%93%81%E7%AB%99/eba8f9346ec3d6a8584477d650c7b46f.JPEG-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%9C%B0%E9%93%81%E7%AB%99/d6949d00a7fb224c49380438ca972e9a.JPEG-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%9C%B0%E9%93%81%E7%AB%99/ab7ef115cfc26858f1647a0893166dfd.JPEG-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%9C%B0%E9%93%81%E7%AB%99/151247fc15f499e6b47679b465ce36c2.JPEG-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%9C%B0%E9%93%81%E7%AB%99/ab5c258fc19e006b20129ce63ee46148.JPEG-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%9C%B0%E9%93%81%E7%AB%99/e4c9b9d428d76f96d6ad07748e278707.jpeg-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%9C%B0%E9%93%81%E7%AB%99/16836aa96ae0269c8c2455a5fcb10cf4.JPEG-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
  ],
}
