import React from "react";
import { GallerySchema, PictureDisplayStyle } from "../../interface";

export const config: GallerySchema = {
  id: 'folkways_3',
  title: '潮洲印象',
  intro: '潮州古城的由來充滿了神秘和詩意。它既是一座受大自然啟發的城市，也是一座歷經歷史沧桑變化的城市。無論是與月亮潮汐的神秘聯繫，還是與唐朝皇帝的歷史淵源，都賦予了這座城市獨特的魅力。這裡不僅有美麗的風景和豐富的文化底蕴，還有那無數讓人著迷的歷史傳說。',
  description: <div>
    潮州古城的由來充滿了神秘和詩意。它既是一座受大自然啟發的城市，也是一座歷經歷史沧桑變化的城市。無論是與月亮潮汐的神秘聯繫，還是與唐朝皇帝的歷史淵源，都賦予了這座城市獨特的魅力。這裡不僅有美麗的風景和豐富的文化底蕴，還有那無數讓人著迷的歷史傳說。
  </div>,
  ratio: [2, 1],
  tags: ['iPhone', 'VSCO', '潮汕'],
  pages: [
    {
      picture: ['http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E6%BD%AE%E6%B4%B2/czyx07.jpeg-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E6%BD%AE%E6%B4%B2/czyx04.jpeg-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E6%BD%AE%E6%B4%B2/czyx02.jpeg-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E6%BD%AE%E6%B4%B2/czyx01.JPG-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E6%BD%AE%E6%B4%B2/czyx08.jpeg-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E6%BD%AE%E6%B4%B2/czyx05.JPG-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E6%BD%AE%E6%B4%B2/czyx03.JPG-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E6%BD%AE%E6%B4%B2/czyx10.jpeg-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E6%BD%AE%E6%B4%B2/czyx06.JPG-smallstyle',
        'http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E6%BD%AE%E6%B4%B2/czyx09.JPG-smallstyle',
      ],
      display: [
        PictureDisplayStyle.FULL,
        PictureDisplayStyle.CENTER,
      ],
    },
  ],
};
