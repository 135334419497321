
import { CategoryCallerySchema } from "./interface";
import { blogList as flokwaysBlogList } from './pages/folkways';
import { blogList as humanitisBlogList } from './pages/humanitis';
import { blogList as travelBlogList } from './pages/travel';
import { blogList as cityBlogList } from './pages/city';

export const HOME_GALLERY_ID = flokwaysBlogList[0].id;

export const folkwaysGallerySchema: CategoryCallerySchema = flokwaysBlogList.reduce((result, blog) => {
  result[blog.id] = blog;
  return result;
}, {} as CategoryCallerySchema);

export const humanitiesGallerySchema: CategoryCallerySchema = humanitisBlogList.reduce((result, blog) => {
  result[blog.id] = blog;
  return result;
}, {} as CategoryCallerySchema);

export const travelGallerySchema: CategoryCallerySchema = travelBlogList.reduce((result, blog) => {
  result[blog.id] = blog;
  return result;
}, {} as CategoryCallerySchema);

export const cityGallerySchema: CategoryCallerySchema = cityBlogList.reduce((result, blog) => {
  result[blog.id] = blog;
  return result;
}, {} as CategoryCallerySchema);

export const categorySchema = {
  folkways: {
    id: 'folkways',
    name: '民俗',
    pages: folkwaysGallerySchema,
  },
  // humanitis: {
  //   id: 'humanitis',
  //   name: '人文',
  //   pages: humanitiesGallerySchema,
  // },
  travel: {
    id: 'travel',
    name: '旅游',
    pages: travelGallerySchema,
  },
  city: {
    id: 'city',
    name: '城市',
    pages: cityGallerySchema,
  },
  // 城市
  // 人像
};
