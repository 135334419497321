import React from "react";
import { GallerySchema, PictureDisplayStyle } from "../../interface";

export const config: GallerySchema = {
  id: 'city_sh_wd',
  title: '雾都',
  intro: '上海位於長江三角洲地區，是一個典型的亞熱帶海洋性氣候，冬季濕冷，夏季炎熱潮濕。由於氣溫和濕度的變化，加上城市的高樓大廈和交通等因素，上海在冬季時常出現大霧天氣。',
  description: <div>
    <p>上海位於長江三角洲地區，是一個典型的亞熱帶海洋性氣候，冬季濕冷，夏季炎熱潮濕。由於氣溫和濕度的變化，加上城市的高樓大廈和交通等因素，上海在冬季時常出現大霧天氣。大霧天氣不僅會影響交通運輸，還會對人們的生活和工作帶來不便。</p>
    <p>上海的大霧天氣主要出現在冬季，尤其是11月至次年2月期間，每年平均有20天左右。大霧天氣給上海帶來的影響十分嚴重，尤其是對交通運輸的影響更為明顯。在大霧天氣下，上海的高速公路、機場和港口等交通樞紐都會受到影響，導致航班延誤、航運受阻、道路擁堵等問題。</p>
    <p>總的來說，上海經常起大霧的原因是多方面的，包括氣候、城市建設和交通等因素。雖然大霧天氣給人們的生活和工作帶來了不便，但也是上海這座城市獨特的氣候現象之一，也是上海城市風貌的一部分。</p>
  </div>,
  // 页面比例
  ratio: [3, 4],
  tags: ['iPhone', 'VSCO', '上海'],
  // 每一页配置
  pages: [
    {
      picture: ['http://cdn.harrybetter.top/%E5%9F%8E%E5%B8%82/%E4%B8%8A%E6%B5%B7/wd06.JPG'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E5%9F%8E%E5%B8%82/%E4%B8%8A%E6%B5%B7/wd03.JPG'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E5%9F%8E%E5%B8%82/%E4%B8%8A%E6%B5%B7/wd01.JPG'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E5%9F%8E%E5%B8%82/%E4%B8%8A%E6%B5%B7/wd05.JPG'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E5%9F%8E%E5%B8%82/%E4%B8%8A%E6%B5%B7/wd02.JPG'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: ['http://cdn.harrybetter.top/%E5%9F%8E%E5%B8%82/%E4%B8%8A%E6%B5%B7/wd04.JPG'],
      display: [PictureDisplayStyle.SPREAD],
    },
  ],
}
