import React from "react";
import { GallerySchema, PictureDisplayStyle } from "../../interface";

export const config: GallerySchema = {
  id: 'folkways_1',
  title: '英歌魂',
  intro: '潮陽英歌舞不僅是一種藝術形式，更是潮陽人民的文化信仰和精神象徵。潮陽英歌舞的傳承和發展，體現了潮陽人民對傳統文化的珍視和傳承精神，也展現了中華優秀傳統文化的光輝。',
  description: <div>
    <p>潮陽英歌舞，是中國廣東潮陽市的一種傳統民間藝術表演形式。潮陽英歌舞源於唐朝，歷經千年傳承至今。潮陽英歌舞以講究韻律、動靜相宜、緊湊有致、富有節奏感等特點而聞名，是潮汕文化的代表之一。</p>
    <p>潮陽英歌舞的表演形式多樣，包括“英打鼓”、“英打棚”、“英打花燭”等，其中以“英打鼓”最為著名。表演者身穿華麗的戲服，手持銅鑼、鼓等樂器，隨著韻律舞動，將濃郁的潮汕文化表現得淋漓盡致。潮陽英歌舞的音樂節奏獨特，旋律優美，富有地方特色，是中國傳統文化的瑰寶之一。</p>
    <p>潮陽英歌舞不僅是一種藝術形式，更是潮陽人民的文化信仰和精神象徵。潮陽英歌舞的傳承和發展，體現了潮陽人民對傳統文化的珍視和傳承精神，也展現了中華優秀傳統文化的光輝。</p>
  </div>,
  ratio: [2, 1],
  tags: ['富士 XT5', 'LR', '潮汕', '庙会'],
  pages: [
    {
      picture: ['http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E8%8B%B1%E6%AD%8C%E9%AD%8201.jpeg-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E8%8B%B1%E6%AD%8C%E9%AD%8202.jpeg-smallstyle',
        'http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E8%8B%B1%E6%AD%8C%E9%AD%8204.jpeg-smallstyle',
      ],
      display: [
        PictureDisplayStyle.CENTER,
        PictureDisplayStyle.FULL,
      ],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E8%8B%B1%E6%AD%8C%E9%AD%8205.jpeg-smallstyle',
        'http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E8%8B%B1%E6%AD%8C%E9%AD%8203.jpeg-smallstyle',
      ],
      display: [
        PictureDisplayStyle.FULL,
        PictureDisplayStyle.CENTER,
      ],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E8%8B%B1%E6%AD%8C%E9%AD%8206.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E8%8B%B1%E6%AD%8C%E9%AD%8209.jpeg-smallstyle',
        'http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E8%8B%B1%E6%AD%8C%E9%AD%8208.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.FULL, PictureDisplayStyle.CENTER],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E6%B0%91%E4%BF%97/%E8%8B%B1%E6%AD%8C%E9%AD%8207.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
    },
  ],
};
