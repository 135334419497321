import { ReactNode } from "react";

export enum PictureDisplayStyle {
  // 单页全屏
  FULL,
  // 单页多图横向全屏
  FULL_HORIZONTAL,
  // 单页多图竖向全屏
  FULL_VERTICAL,
  // 居中留白
  CENTER,
  // 单页多图横向居中留白
  CENTER_HORIZONTAL,
  // 单页多图竖向居中留白
  CENTER_VERTICAL,
  // 跨页全屏
  SPREAD,
}

export interface GalleryPage {
  picture: string[];
  display: PictureDisplayStyle[];
  style?: { [key: string ]: string | number };
}

export interface GallerySchema {
  id: string;
  title: string;
  intro: string;
  description: string | ReactNode;
  ratio: number[];
  tags: string[];
  pages: GalleryPage[];
}

export interface CategoryCallerySchema {
  [key: string]: GallerySchema;
}
