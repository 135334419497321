import React from "react";
import { GallerySchema, PictureDisplayStyle } from "../../interface";

export const config: GallerySchema = {
  id: 'travel_1',
  title: '云南游',
  intro: '雲南是一個充滿神秘和多彩文化的地方，擁有眾多美麗的景點。其中，昆明、大理、麗江、瀘沽湖是雲南省最著名的幾個旅遊目的地。',
  description: <div>
    <p>雲南是一個充滿神秘和多彩文化的地方，擁有眾多美麗的景點。其中，昆明、大理、麗江、瀘沽湖是雲南省最著名的幾個旅遊目的地。</p>
    <p>昆明市是雲南省的省會，也是雲南省最大的城市之一。昆明市有許多著名的景點，其中最著名的是石林風景區。石林風景區是一個由石柱和石峰組成的獨特地質景觀，形態各異，非常壯觀。此外，昆明市還有滇池、翠湖、金馬碧雞坊等著名景點。</p>
    <p>大理市位於雲南省西北部，是一個具有豐富歷史和文化的城市。大理古城是大理市最著名的景點之一，是一座保存完好的古城堡壘，被譽為「南方小鎮的故事」。此外，大理還有洱海、三塔等著名景點。</p>
    <p>麗江市位於雲南省西北部，是一個具有濃郁少數民族文化的地方。麗江古城是麗江市最著名的景點之一，是一座保存完好的古城堡壘，被譽為「東方威尼斯」。此外，麗江還有玉龍雪山、束河古鎮等著名景點。</p>
    <p>瀘沽湖位於雲南省西北部，是一個美麗的高山湖泊。瀘沽湖周圍的山坡上種植了大量的花卉，春天時，花海絢麗，景色十分美麗。此外，瀘沽湖還有摩梭人村寨等著名景點。</p>
    <p>雲南省還有許多其他著名景點，如獨克宗古城、普洱茶山、西雙版納熱帶雨林等，都值得一遊。無論是自然景觀還是人文景觀，雲南省都有著豐富多彩的旅遊資源，是一個非常值得一去的地方。</p>
  </div>,
  // 页面比例
  ratio: [2, 1],
  tags: ['佳能 550D', '云南'],
  // 每一页配置
  pages: [
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/F2995453-C2CF-48C8-838B-50201C381A75.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
      style: { marginTop: '-12%' },
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/8A2DB2A0-B1A1-4091-8091-2E615BDDA9D9.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/12B87610-1925-4B39-A595-9D9E2A8A0D32.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
      style: { marginTop: '-12%', marginRight: '-5%' },
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/AA459906-8CE7-4A13-8692-E7125FED9443.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
      style: { marginTop: '16%' },
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/93E70454-3832-4F49-8851-8F47D715CB60.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/27264647-57F4-4DF3-AA0E-5AE9CA1CCA87.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/659235C0-1763-42A0-BB23-3698561CF679.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/B35AE9C0-9C0F-45FA-9A86-B3B6732BDED2.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
      style: { marginTop: '-12%' },
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/BEA243EB-CABB-4B57-82AE-F8A82FF47BFB.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
      style: { marginTop: '16%' },
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/E1D7994A-ABE5-4744-AEC1-23F2F391DB2D.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
      style: { marginTop: '-12%' },
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/BA871A1D-315F-4840-80ED-2ACB24CC7522.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
      style: { marginTop: '-12%' },
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/DF242E9B-2F2C-46E3-923D-14BEEAE20580.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
      style: { marginTop: '-12%' },
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/6BC23537-A44B-4A85-8EE6-6440398AE1A5.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/E0ABFA97-2211-400E-9650-C0A4CAF76610.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/5B15EC58-4E3C-4CFB-9783-20B72DC1B540.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.SPREAD],
      style: { marginTop: '-20%' },
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/AF1ACC8B-CAC0-45BC-A779-410E57BA59D3.jpeg-smallstyle',
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/A6C348BB-ABB1-404A-8AF7-5C655F3498B9.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.FULL, PictureDisplayStyle.CENTER],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/DAF510D0-C441-482F-941D-9CE778401E6B.jpeg-smallstyle',
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/B19FCDD5-5813-4450-8822-AE65ACCA7380.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.CENTER, PictureDisplayStyle.FULL],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/B48309D5-7CCD-4C25-98C8-02016BF3E66E.jpeg-smallstyle',
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/0682337B-4C54-415E-B4F9-F4D00860C828.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.FULL, PictureDisplayStyle.CENTER],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/689DB416-3B4D-428C-88A8-41E18A00EDD6.jpeg-smallstyle',
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/33449666-3D45-4F9C-9A3B-427B3A4E1F20.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.CENTER, PictureDisplayStyle.FULL],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/9C0D9E7B-D999-4EA1-B6A8-7ED8F3432D7B.jpeg-smallstyle',
        'http://cdn.harrybetter.top/%E4%BA%91%E5%8D%97/7D707436-B649-4B61-82D5-8368083B45DE.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.FULL, PictureDisplayStyle.CENTER],
    },
  ],
}
