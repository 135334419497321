import React from "react";
import { GallerySchema, PictureDisplayStyle } from "../../interface";

export const config: GallerySchema = {
  id: 'city_hk_chc',
  title: '彩虹邨',
  intro: '彩虹邨見證了香港的發展歷程，是香港公共房屋的一個重要里程碑。彩虹邨不僅提供了經濟實惠的住房，也成為了一個充滿活力和生命力的社區，展現了香港多元文化的魅力。',
  description: <div>
    <p>彩虹邨是香港的一個公共房屋屋邨，位於九龍觀塘區，建於1975年，是香港最早的公共房屋之一。彩虹邨共有十二座大廈，樓高三十至四十層不等，提供約6,000個單位，是香港最大的公共房屋之一。</p>
    <p>彩虹邨的設計獨特，以彩虹為主題，每座大廈的外牆都是不同的顏色，從遠處看去，彩虹邨就像一條繽紛的彩虹橋。彩虹邨的公共空間設施齊全，包括學校、商場、運動場、圖書館等，為居民提供了便利的生活環境。</p>
    <p>彩虹邨的居民以低收入家庭為主，他們來自不同的族裔和文化背景，形成了一個多元化的社區。彩虹邨的社區活動豐富多彩，有各種文化、體育、藝術等活動，居民之間互相幫助，形成了一個和諧、友愛的社區。</p>
    <p>彩虹邨見證了香港的發展歷程，是香港公共房屋的一個重要里程碑。彩虹邨不僅提供了經濟實惠的住房，也成為了一個充滿活力和生命力的社區，展現了香港多元文化的魅力。</p>
  </div>,
  // 页面比例
  ratio: [2, 1],
  tags: ['iPhone', 'VSCO', '香港'],
  // 每一页配置
  pages: [
    {
      picture: ['http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%BD%A9%E8%99%B9%E6%9D%91/04c3a2a3acdce2ceb03fc44257e9634d.jpeg-smallstyle'],
      display: [PictureDisplayStyle.SPREAD],
      style: { marginTop: '-16%', },
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%BD%A9%E8%99%B9%E6%9D%91/3a0e677df45339c2397162ebc3813fcf.jpeg-smallstyle',
        'http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%BD%A9%E8%99%B9%E6%9D%91/f4f58481a3db38eae6781b0371cf8212.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.CENTER, PictureDisplayStyle.FULL],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/2019-05-01-hk-1ADA6A61-187B-42FE-A4E3-190B78E25B8D.JPG-smallstyle', 
        'http://cdn.harrybetter.top/2019-05-01-hk-2497B4BE-98A8-4E56-8C91-1961A5B94611.JPG-smallstyle',
      ],
      display: [PictureDisplayStyle.FULL, PictureDisplayStyle.CENTER],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%BD%A9%E8%99%B9%E6%9D%91/d45fc4d0b08d6b3d8dc53164a147c0cd.jpeg-smallstyle',
        'http://cdn.harrybetter.top/2019-05-01-hk-BD955151-D297-46B6-BC17-8EC0CF83092F.JPG-smallstyle',
      ],
      display: [PictureDisplayStyle.CENTER, PictureDisplayStyle.FULL],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/2019-05-01-hk-4226B921-5F0C-4395-AE32-F870C646FDA5.JPG-smallstyle',
        'http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%BD%A9%E8%99%B9%E6%9D%91/efc2634b83a843fda2d0dbb966146ca8.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.FULL, PictureDisplayStyle.CENTER],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%BD%A9%E8%99%B9%E6%9D%91/27418f6a600effdf177b6074adeeeb59.jpeg-smallstyle',
        'http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%BD%A9%E8%99%B9%E6%9D%91/9b7f1524eb03048a39edca5cd1e6a7a4.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.CENTER, PictureDisplayStyle.FULL],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%BD%A9%E8%99%B9%E6%9D%91/530d5094a4c31ab29fb81a14ea710b7c.jpeg-smallstyle',
        'http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%BD%A9%E8%99%B9%E6%9D%91/08a21ff2db2d7d8e12b722ee5851e1c0.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.CENTER, PictureDisplayStyle.CENTER],
    },
    {
      picture: [
        'http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%BD%A9%E8%99%B9%E6%9D%91/a00d52209242c4f0989b88f1d95a3f49.jpeg-smallstyle',
        'http://cdn.harrybetter.top/%E9%A6%99%E6%B8%AF/%E5%BD%A9%E8%99%B9%E6%9D%91/717ce2ebf8c7b31026c14a388d3441af.jpeg-smallstyle',
      ],
      display: [PictureDisplayStyle.CENTER, PictureDisplayStyle.CENTER],
    },
  ],
}
